import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["dropdown", "input", "holder"];

  connect() {
    $(this.dropdownTarget).on("change", (event) => {
      this.insertField(event);
    });
  }

  openDropdown(event) {
    this.holderTarget.style.display = "block";
  }

 insertField(event) {
    const selectedValue = this.dropdownTarget.value;
    const closestInput = this.inputTarget;

    if (closestInput && selectedValue != "disabledselectoption") {

      var content = closestInput.value;
      var position = this.getCursorPosition(closestInput)
      var newContent = content.substr(0, position) + "{("+ selectedValue +")}" + content.substr(position);
      closestInput.value = newContent;


      setTimeout(
        this.closeDropdown()
      , 500);
    } else {
      
    }
  }

  closeDropdown(event) {
    this.dropdownTarget.value = "disabledselectoption";
    this.holderTarget.style.display = "none";
  }

  getCursorPosition(inputElement) {
    return inputElement.selectionStart;
  }
}